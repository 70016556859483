<template>
  <div class="auth-container">

    <div class="auth-container__header">
      <AppLogo />
    </div>

    <div class="auth-container__body">

      <div class="auth-container__content">

        <div class="auth-container__form-wrapper">
          <h1 v-if="title" class="auth-container__title">{{ title }}</h1>
          <p v-if="comment" class="auth-container__comment">
            After filling these initial details, you will later be able to add information by accessing
            <strong>{{ comment }}</strong>
            in the Settings.
          </p>

          <div class="auth-container__form-slot" :class="{ 'auth-container__form-slot_short': !comment }">
            <slot></slot>
          </div>

<!--          <div class="auth-container__footer">-->
<!--            <router-link to="#" class="login-form__link">Terms & Conditions</router-link> |-->
<!--            <router-link to="#" class="login-form__link">Privacy</router-link> |-->
<!--            <router-link to="#" class="login-form__link">Support</router-link>-->
<!--          </div>-->
        </div>

      </div>

      <div class="auth-container__banner" :style="background">
        <div class="auth-container__banner-partial"></div>
        <div class="auth-container__banner-title">
          <h3>Contracts Connected</h3>
          <h4>
            In private ALPHA. Interested?
          </h4>
        </div>
        <div class="auth-container__banner-footer auth-container__banner-partial">
          <span>
            Copyright @2023 - Contracts Connected
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="34" viewBox="0 0 120 34" fill="none">
            <path d="M29.6659 9.06055L24.6859 11.9005C24.7358 11.963 24.7649 12.0393 24.769 12.1188V15.7674C24.769 15.8207 24.7476 15.8718 24.7094 15.9094C24.6713 15.9471 24.6196 15.9683 24.5657 15.9683H20.3653C20.3113 15.9683 20.2594 15.9472 20.221 15.9095C20.1825 15.8719 20.1607 15.8208 20.1603 15.7674V14.7194C20.1578 14.6494 20.1351 14.5815 20.0949 14.5238C20.0667 14.4808 20.0293 14.4444 19.9853 14.4173L15.2546 11.6926C15.1998 11.6652 15.1392 11.6509 15.0778 11.6509C15.0163 11.6509 14.9558 11.6652 14.901 11.6926L10.195 14.4155C10.1512 14.4423 10.1142 14.4788 10.0872 14.5221C10.0465 14.5797 10.0232 14.6475 10.02 14.7177V20.0832C10.0234 20.1437 10.0412 20.2026 10.0719 20.2551C10.1026 20.3076 10.1454 20.3522 10.1968 20.3853L14.901 23.0593C14.959 23.0899 15.0244 23.1044 15.0901 23.1013C15.147 23.1009 15.203 23.0865 15.2528 23.0593L19.9835 20.3836C20.0351 20.3511 20.078 20.3068 20.1088 20.2546C20.1395 20.2023 20.1572 20.1436 20.1603 20.0832V19.0911C20.1607 19.0377 20.1825 18.9866 20.221 18.949C20.2594 18.9114 20.3113 18.8903 20.3653 18.8903H24.5657C24.5924 18.8903 24.6188 18.8955 24.6435 18.9056C24.6682 18.9156 24.6906 18.9304 24.7094 18.9491C24.7283 18.9677 24.7433 18.9899 24.7535 19.0143C24.7637 19.0386 24.769 19.0648 24.769 19.0911V22.731C24.7656 22.7915 24.7479 22.8504 24.7171 22.9029C24.6864 22.9554 24.6436 23 24.5922 23.0331L15.2439 28.3218C15.1973 28.3471 15.1451 28.3609 15.0919 28.3619V34.0016C15.7073 34.015 16.3159 33.8725 16.8597 33.5877L28.4284 26.9891C29.3972 26.4355 30.1962 25.0801 30.1962 23.9745V10.7757C30.1707 10.1686 29.9881 9.57807 29.6659 9.06055Z" fill="white"/>
            <path d="M14.8904 28.32L5.58987 23.0314C5.53845 22.9983 5.49565 22.9537 5.46493 22.9012C5.43422 22.8487 5.41646 22.7898 5.41308 22.7292V12.1188C5.41721 12.0393 5.44625 11.963 5.49617 11.9005L0.517973 9.06055C0.200343 9.57873 0.0220424 10.1685 0 10.7739V23.9728C0 25.0784 0.791986 26.4337 1.76783 26.9874L13.3365 33.5859C13.8805 33.8704 14.489 34.0129 15.1043 33.9999V28.3602C15.0305 28.3694 14.9556 28.3553 14.8904 28.32Z" fill="white"/>
            <path d="M5.58979 11.8169L14.8921 6.42868C14.9467 6.40052 15.0073 6.3858 15.0689 6.3858C15.1304 6.3858 15.1911 6.40052 15.2457 6.42868L24.5975 11.8169C24.6334 11.8386 24.6646 11.867 24.6894 11.9007L29.6693 9.06076C29.3751 8.53088 28.9493 8.08371 28.4319 7.76132L16.8632 1.16101C15.8944 0.609091 14.3087 0.609091 13.3399 1.16101L1.77129 7.76132C1.25338 8.08311 0.827384 8.53043 0.533813 9.06076L5.51201 11.9007C5.53269 11.8684 5.55903 11.84 5.58979 11.8169Z" fill="white"/>
            <path d="M39.3023 7.95681C39.6475 7.62277 40.0576 7.36134 40.5079 7.18832C40.9987 7.00273 41.5205 6.9103 42.0459 6.91586C42.5764 6.91266 43.1019 7.01792 43.5892 7.22501C44.087 7.44493 44.5349 7.76151 44.9063 8.15592L44.9416 8.1926L46.3188 6.91411L46.287 6.87569C45.7793 6.28157 45.1341 5.81724 44.406 5.5221C42.8737 4.93315 41.1767 4.91638 39.6329 5.47494C38.9409 5.73947 38.3084 6.13589 37.7713 6.64164C37.2434 7.1418 36.8226 7.74169 36.5339 8.40567C35.95 9.83301 35.95 11.4286 36.5339 12.856C36.8228 13.5234 37.2446 14.1267 37.7742 14.6299C38.3039 15.1331 38.9305 15.5259 39.6169 15.7849C40.3602 16.0643 41.15 16.2035 41.9452 16.1954C42.7934 16.2066 43.6352 16.0486 44.4201 15.7308C45.148 15.4312 45.7929 14.9641 46.3011 14.3685L46.3329 14.3318L44.9558 13.0516L44.9187 13.09C44.5517 13.4921 44.1037 13.814 43.6034 14.0349C43.1163 14.2428 42.5907 14.3481 42.0601 14.344C41.5347 14.3501 41.0129 14.2583 40.5221 14.0733C40.0715 13.8996 39.6613 13.6374 39.3161 13.3027C38.971 12.968 38.6981 12.5677 38.5138 12.1259C38.1273 11.1651 38.1273 10.0947 38.5138 9.134C38.6956 8.69413 38.9635 8.29407 39.3023 7.95681Z" fill="white"/>
            <path d="M53.9345 8.20331C53.2242 7.82894 52.429 7.63958 51.624 7.65313C50.8228 7.63849 50.0313 7.82797 49.3258 8.20331C48.3283 8.77817 47.6013 9.71932 47.3033 10.8216C47.0052 11.9238 47.1603 13.0978 47.7348 14.0875C48.118 14.7233 48.6676 15.2452 49.3258 15.5983C50.0261 15.9708 50.8111 16.1602 51.6063 16.1485C52.4071 16.1595 53.1976 15.9702 53.9045 15.5983C54.5633 15.2492 55.1134 14.7293 55.4955 14.0945C55.8719 13.4219 56.0694 12.6659 56.0694 11.8973C56.0694 11.1287 55.8719 10.3727 55.4955 9.70011C55.1191 9.07295 54.5803 8.55627 53.9345 8.20331ZM52.8738 14.1137C52.4956 14.3213 52.0689 14.4273 51.6364 14.4211C51.2112 14.426 50.7923 14.3199 50.4219 14.1137C50.0569 13.9085 49.7579 13.6059 49.5592 13.2404C49.3411 12.8304 49.2316 12.3726 49.2409 11.9095C49.2293 11.4468 49.3389 10.989 49.5592 10.5804C49.7598 10.2163 50.0583 9.91411 50.4219 9.7071C50.7995 9.50532 51.2221 9.39966 51.6514 9.39966C52.0807 9.39966 52.5033 9.50532 52.8809 9.7071C53.2431 9.91345 53.5396 10.216 53.7365 10.5804C53.9511 10.9908 54.0581 11.4477 54.0477 11.9095C54.0564 12.3719 53.9489 12.8292 53.7348 13.2404C53.5375 13.6081 53.2366 13.9114 52.8685 14.1137H52.8738Z" fill="white"/>
            <path d="M64.1614 8.04269C63.601 7.7747 62.9844 7.64125 62.3618 7.65321C61.6874 7.63951 61.0199 7.7888 60.4172 8.0881C60.1 8.24952 59.814 8.46471 59.5722 8.72386V7.7615H57.6276V16.035H59.6606V11.8432C59.6436 11.3786 59.74 10.9167 59.9416 10.4966C60.1153 10.1567 60.3914 9.87846 60.7319 9.70019C61.0982 9.51549 61.5051 9.42309 61.9163 9.43121C62.528 9.43121 63.0053 9.60587 63.3306 9.95518C63.6558 10.3045 63.8291 10.8285 63.8291 11.5428V16.035H65.8709V11.2966C65.8984 10.5817 65.7389 9.87183 65.4078 9.23559C65.1221 8.72398 64.6881 8.30853 64.1614 8.04269Z" fill="white"/>
            <path d="M72.9582 14.1415C72.6674 14.3649 72.3063 14.4799 71.9382 14.4663C71.7859 14.4754 71.6334 14.453 71.4904 14.4007C71.3473 14.3484 71.2169 14.2673 71.1073 14.1624C70.8975 13.9205 70.7915 13.6071 70.8121 13.2891V9.44668H73.0448V7.82586H70.8121V5.95703H68.7844V7.82586H67.4214V9.46065H68.7844V13.359C68.7844 14.269 69.0407 14.9693 69.5445 15.4409C70.0484 15.9125 70.7696 16.1518 71.6889 16.1518C72.0344 16.1516 72.3782 16.1045 72.7107 16.012C73.0264 15.9289 73.3225 15.7853 73.5823 15.5894L73.6123 15.5649L73.013 14.1013L72.9582 14.1415Z" fill="white"/>
            <path d="M78.2228 8.0968C77.8826 8.27713 77.5864 8.52867 77.3548 8.83385V7.76145H75.3943V16.035H77.4273V12.0318C77.4273 11.1935 77.6412 10.5629 78.0619 10.156C78.4827 9.74904 79.0661 9.54294 79.7926 9.54294C79.8527 9.54294 79.9164 9.54294 79.9889 9.54294C80.0613 9.54294 80.1321 9.55692 80.2028 9.56565H80.2629V7.65141H80.2098C79.5202 7.63283 78.8368 7.786 78.2228 8.0968Z" fill="white"/>
            <path d="M87.9316 8.52468C87.274 7.94656 86.3247 7.65139 85.1031 7.65139C84.4532 7.64973 83.8063 7.73851 83.1815 7.91512C82.6046 8.07896 82.0626 8.34465 81.5816 8.69933L81.5462 8.72554L82.3577 10.2136L82.4072 10.1752C82.7398 9.90846 83.1191 9.70424 83.5262 9.57262C83.956 9.43382 84.4052 9.36251 84.8574 9.36129C85.5433 9.36129 86.0613 9.51324 86.3954 9.81365C86.5664 9.97768 86.6997 10.1761 86.7863 10.3955C86.8729 10.6149 86.9108 10.8501 86.8974 11.0852V11.155H84.7973C84.104 11.1308 83.4127 11.2412 82.7625 11.4799C82.3032 11.6528 81.9117 11.9661 81.6452 12.3741C81.4161 12.758 81.2981 13.1966 81.304 13.6421C81.2982 14.1019 81.4339 14.5526 81.693 14.9346C81.9681 15.3272 82.349 15.6358 82.7926 15.8254C83.3131 16.0465 83.8755 16.1549 84.4419 16.1432C85.085 16.1676 85.7237 16.0281 86.2964 15.738C86.5648 15.5917 86.8009 15.3939 86.9911 15.1564V16.0297H88.9269V11.1969C88.9269 10.004 88.5928 9.10454 87.9316 8.52468ZM86.8992 13.4151C86.7427 13.8002 86.4602 14.1224 86.0966 14.3303C85.7147 14.5422 85.283 14.6507 84.845 14.6447C84.4454 14.6679 84.0489 14.5638 83.7136 14.3477C83.5877 14.2625 83.4854 14.1476 83.416 14.0134C83.3465 13.8792 83.3121 13.7299 83.3158 13.5792C83.3128 13.4377 83.341 13.2973 83.3984 13.1676C83.4558 13.0379 83.541 12.9221 83.6482 12.8282C83.8692 12.6291 84.304 12.5278 84.9369 12.5278H86.8992V13.4151Z" fill="white"/>
            <path d="M97.1579 13.298C96.942 13.6509 96.6375 13.9427 96.274 14.1451C95.9264 14.3282 95.5382 14.423 95.1443 14.4211C94.7039 14.4273 94.2692 14.3214 93.8821 14.1137C93.5087 13.9126 93.2018 13.6094 92.9982 13.2404C92.7775 12.8288 92.6674 12.3682 92.6782 11.9025C92.6671 11.4396 92.7774 10.9818 92.9982 10.5734C93.2031 10.2054 93.5096 9.9025 93.8821 9.7001C94.2692 9.4924 94.7039 9.38654 95.1443 9.3927C95.5383 9.39136 95.9265 9.48681 96.274 9.67042C96.6357 9.86833 96.94 10.1545 97.1579 10.5018L97.1862 10.5437L98.7436 9.63722L98.7224 9.59356C98.4197 8.97401 97.9241 8.46657 97.3082 8.14564C96.6446 7.8076 95.9067 7.63719 95.1602 7.64962C94.3457 7.63591 93.5407 7.82513 92.8196 8.1998C92.1571 8.54702 91.6014 9.06404 91.2109 9.6966C90.8296 10.3676 90.6294 11.1243 90.6294 11.8938C90.6294 12.6633 90.8296 13.4199 91.2109 14.091C91.5991 14.7272 92.1552 15.247 92.8196 15.5948C93.5407 15.9694 94.3457 16.1587 95.1602 16.145C95.9081 16.1574 96.6472 15.9845 97.3099 15.6419C97.9221 15.3202 98.4167 14.8168 98.7242 14.2028L98.7472 14.1591L97.1879 13.2509L97.1579 13.298Z" fill="white"/>
            <path d="M105.148 14.1415C104.857 14.3651 104.495 14.4802 104.127 14.4663C103.975 14.4755 103.822 14.4531 103.68 14.4008C103.537 14.3485 103.407 14.2673 103.298 14.1624C103.088 13.9205 102.982 13.6071 103.002 13.2891V9.44668H105.235V7.82586H103.002V5.95703H100.975V7.82586H99.6134V9.46065H100.976V13.359C100.976 14.269 101.233 14.9693 101.737 15.4409C102.24 15.9125 102.962 16.1518 103.881 16.1518C104.226 16.1516 104.57 16.1045 104.903 16.012C105.218 15.9289 105.515 15.7853 105.774 15.5894L105.804 15.5649L105.205 14.1013L105.148 14.1415Z" fill="white"/>
            <path d="M113.699 12.4249C113.513 12.1355 113.252 11.8995 112.945 11.7402C112.623 11.5761 112.283 11.4512 111.932 11.3682C111.578 11.2809 111.202 11.2093 110.836 11.1534C110.47 11.0975 110.128 11.0364 109.84 10.9787C109.59 10.9299 109.35 10.8363 109.133 10.7028C109.049 10.6483 108.981 10.5727 108.936 10.4837C108.891 10.3947 108.87 10.2955 108.877 10.1963C108.882 10.0679 108.923 9.94343 108.995 9.83651C109.067 9.72959 109.167 9.64436 109.285 9.59019C109.565 9.42602 109.992 9.34392 110.56 9.34392C110.958 9.34536 111.354 9.38871 111.742 9.47319C112.152 9.56496 112.544 9.7206 112.904 9.93426L112.952 9.96222L113.76 8.41475L113.717 8.3903C113.265 8.13818 112.776 7.95783 112.268 7.85586C111.712 7.72636 111.144 7.65782 110.574 7.6515C109.911 7.63793 109.251 7.74876 108.629 7.97811C108.126 8.16269 107.682 8.47758 107.344 8.88982C107.038 9.28215 106.876 9.76559 106.884 10.2609C106.864 10.6722 106.964 11.0807 107.171 11.4381C107.359 11.7276 107.616 11.9674 107.919 12.1367C108.236 12.3121 108.577 12.4427 108.932 12.5244C109.298 12.6118 109.671 12.6816 110.037 12.7323C110.402 12.7829 110.744 12.8406 111.034 12.9069C111.283 12.9521 111.522 13.0402 111.741 13.1672C111.822 13.2158 111.888 13.2851 111.933 13.3677C111.978 13.4504 111.999 13.5434 111.995 13.637C111.996 13.7637 111.959 13.8878 111.891 13.9951C111.823 14.1023 111.726 14.1883 111.61 14.2431C111.346 14.395 110.915 14.4719 110.328 14.4719C109.803 14.4714 109.281 14.3979 108.776 14.2536C108.311 14.1268 107.867 13.935 107.457 13.6842L107.408 13.6527L106.618 15.2002L106.656 15.2264C107.13 15.5222 107.647 15.7443 108.189 15.8849C108.844 16.0662 109.522 16.1567 110.203 16.1538C110.887 16.169 111.569 16.0619 112.214 15.8377C112.722 15.66 113.173 15.3534 113.523 14.9487C113.837 14.5593 114.002 14.0735 113.991 13.5759C114.008 13.1725 113.907 12.7728 113.699 12.4249Z" fill="white"/>
            <path d="M38.6005 21.1942C39.0127 20.7937 39.5028 20.48 40.0412 20.272C40.6122 20.0513 41.2207 19.9404 41.8338 19.9454C42.4193 19.9413 43.0007 20.0412 43.5504 20.2406C44.1007 20.4508 44.5969 20.7788 45.0035 21.2012L45.0406 21.2396L45.8432 20.4467L45.8097 20.41C45.324 19.8817 44.718 19.4752 44.0418 19.2241C43.3269 18.9594 42.569 18.8256 41.8055 18.8294C41.0255 18.8222 40.2511 18.9609 39.5233 19.2381C38.8444 19.4992 38.2249 19.891 37.7006 20.3908C37.1833 20.8864 36.7731 21.4806 36.495 22.1374C35.9198 23.5452 35.9198 25.1186 36.495 26.5265C36.7716 27.1853 37.1806 27.7819 37.6971 28.2802C38.2137 28.7785 38.8271 29.1683 39.5003 29.4258C40.9619 29.9649 42.5713 29.9649 44.033 29.4258C44.7089 29.1709 45.3146 28.7622 45.8008 28.2329L45.8344 28.1962L45.0318 27.4033L44.9947 27.4417C44.5865 27.8639 44.0908 28.1939 43.5415 28.4093C42.9931 28.6137 42.4111 28.7161 41.825 28.7115C41.212 28.7163 40.6037 28.6061 40.0324 28.3866C39.4942 28.1773 39.0042 27.8631 38.5916 27.4627C38.1835 27.0631 37.8586 26.5884 37.6352 26.0654C37.176 24.9452 37.176 23.6925 37.6352 22.5723C37.8633 22.0553 38.1911 21.5872 38.6005 21.1942Z" fill="white"/>
            <path d="M53.4944 22.0608C52.8287 21.7121 52.0867 21.5298 51.3332 21.5298C50.5798 21.5298 49.8378 21.7121 49.1721 22.0608C48.551 22.4054 48.0355 22.9093 47.68 23.5191C47.3192 24.1784 47.1302 24.9162 47.1302 25.6657C47.1302 26.4152 47.3192 27.1529 47.68 27.8122C48.0337 28.4294 48.5491 28.941 49.1721 29.2933C49.8278 29.6571 50.5697 29.8422 51.3217 29.8295C52.0781 29.8409 52.8242 29.656 53.4856 29.2933C54.113 28.947 54.6322 28.4371 54.9864 27.8192C55.3541 27.1629 55.5407 26.4227 55.5274 25.6727C55.5429 24.9224 55.3562 24.1816 54.9864 23.5261C54.6304 22.9149 54.1152 22.4089 53.4944 22.0608ZM51.3217 28.7536C50.7909 28.763 50.2674 28.6302 49.8067 28.3694C49.3608 28.1095 48.9943 27.7353 48.746 27.2865C48.4858 26.7837 48.3501 26.2269 48.3501 25.6622C48.3501 25.0974 48.4858 24.5407 48.746 24.0379C48.9953 23.592 49.3617 23.2209 49.8067 22.9637C50.2739 22.7121 50.7976 22.5803 51.3297 22.5803C51.8618 22.5803 52.3854 22.7121 52.8527 22.9637C53.2995 23.2185 53.6666 23.5902 53.9134 24.0379C54.1691 24.5421 54.3022 25.0982 54.3022 25.6622C54.3022 26.2261 54.1691 26.7823 53.9134 27.2865C53.6684 27.7378 53.3011 28.1127 52.8527 28.3694C52.3859 28.6295 51.8575 28.7621 51.3217 28.7536Z" fill="white"/>
            <path d="M63.5675 21.907C63.0167 21.6445 62.411 21.5141 61.7997 21.5262C61.0808 21.5079 60.3703 21.6823 59.7437 22.031C59.3493 22.2563 59.011 22.566 58.7537 22.9375V21.6031H57.594V29.7456H58.7997V25.4665C58.7844 24.9319 58.9021 24.4017 59.1426 23.9225C59.3599 23.5089 59.6981 23.1693 60.1131 22.9479C60.5719 22.7149 61.0824 22.599 61.5981 22.6108C62.3547 22.6108 62.9487 22.8239 63.3659 23.2449C63.7832 23.6658 63.9935 24.2928 63.9935 25.1311V29.7456H65.201V25.0298C65.2243 24.3528 65.0754 23.6809 64.7678 23.0754C64.4987 22.5734 64.0796 22.1654 63.5675 21.907Z" fill="white"/>
            <path d="M74.0437 21.907C73.4929 21.6445 72.8872 21.5141 72.2758 21.5262C71.557 21.5079 70.8465 21.6823 70.2199 22.031C69.8255 22.2563 69.4872 22.566 69.2299 22.9375V21.6031H68.0702V29.7456H69.2758V25.4665C69.2606 24.9319 69.3783 24.4017 69.6188 23.9225C69.8361 23.5089 70.1743 23.1693 70.5893 22.9479C71.0481 22.7149 71.5586 22.599 72.0743 22.6108C72.8309 22.6108 73.4249 22.8239 73.8421 23.2449C74.2593 23.6658 74.4697 24.2928 74.4697 25.1311V29.7456H75.6771V25.0298C75.7005 24.3528 75.5516 23.6809 75.244 23.0754C74.9749 22.5734 74.5558 22.1654 74.0437 21.907Z" fill="white"/>
            <path d="M83.8249 22.0463C83.1919 21.7026 82.4807 21.5235 81.7583 21.5259C81.0359 21.5283 80.326 21.7122 79.6953 22.0603C79.0891 22.4122 78.5902 22.919 78.251 23.5274C77.8902 24.1827 77.7074 24.9193 77.7206 25.6652C77.7077 26.4168 77.8975 27.1583 78.2704 27.8135C78.6321 28.4331 79.1593 28.9425 79.7943 29.2859C80.4875 29.6537 81.2651 29.8384 82.0518 29.822C82.6728 29.8263 83.2884 29.7075 83.8621 29.4727C84.3953 29.2565 84.8673 28.9155 85.2374 28.4789L85.2657 28.444L84.5763 27.6615L84.5374 27.7069C84.2431 28.0503 83.8696 28.3187 83.4484 28.4894C83.0142 28.6622 82.55 28.7495 82.0819 28.7462C81.506 28.7562 80.9366 28.6242 80.4254 28.3619C79.956 28.1171 79.5657 27.7467 79.2993 27.293C79.0785 26.9113 78.9463 26.486 78.9122 26.0477H85.7466V25.9953C85.7466 25.9342 85.7466 25.8783 85.7466 25.8207C85.7466 25.763 85.7466 25.7071 85.7466 25.6547C85.7613 24.9029 85.5831 24.1596 85.2286 23.4942C84.9039 22.8935 84.4184 22.3927 83.8249 22.0463ZM79.2657 23.9903C79.4984 23.5531 79.8514 23.1898 80.284 22.9423C80.7329 22.6881 81.2428 22.5578 81.7601 22.565C82.2775 22.5559 82.7877 22.6857 83.2363 22.9406C83.6628 23.1934 84.0122 23.5555 84.2475 23.9885C84.4429 24.3465 84.5632 24.7397 84.601 25.1447H78.9298C78.9646 24.742 79.0787 24.3498 79.2657 23.9903Z" fill="white"/>
            <path d="M93.815 27.5955C93.5602 27.9709 93.2087 28.2724 92.7967 28.4688C92.3828 28.6614 91.9302 28.7593 91.4726 28.7553C90.9342 28.7626 90.4032 28.6301 89.9329 28.371C89.4794 28.1175 89.1056 27.7452 88.8527 27.2951C88.5925 26.7911 88.4568 26.2332 88.4568 25.6673C88.4568 25.1015 88.5925 24.5436 88.8527 24.0395C89.1059 23.5901 89.4796 23.2184 89.9329 22.9654C90.3699 22.7309 90.8561 22.6001 91.3531 22.5831C91.8502 22.5661 92.3444 22.6635 92.7967 22.8676C93.2111 23.0662 93.563 23.3722 93.815 23.7531L93.8451 23.7968L94.7502 23.1872L94.7254 23.1453C94.4129 22.6143 93.946 22.1884 93.3854 21.9227C92.7915 21.6483 92.1424 21.5104 91.4868 21.5192C90.7245 21.506 89.9715 21.6864 89.3 22.0432C88.669 22.3826 88.1448 22.8872 87.785 23.5016C87.4241 24.1625 87.2352 24.9016 87.2352 25.6525C87.2352 26.4034 87.4241 27.1425 87.785 27.8034C88.1447 28.4215 88.6686 28.9306 89.3 29.2758C89.9694 29.6392 90.7231 29.824 91.4868 29.812C92.1434 29.8216 92.7934 29.6805 93.3854 29.3998C93.9406 29.1344 94.406 28.7158 94.7254 28.1946L94.752 28.151L93.8451 27.5432L93.815 27.5955Z" fill="white"/>
            <path d="M100.946 28.383C100.619 28.6554 100.2 28.7979 99.7725 28.783C99.5879 28.7951 99.4028 28.7687 99.2292 28.7055C99.0556 28.6423 98.8974 28.5438 98.7649 28.4162C98.5148 28.125 98.3882 27.7496 98.4113 27.3683V22.6141H100.886V21.6064H98.4113L98.4856 19.8301H97.2799L97.2039 21.6064H95.7472V22.6141H97.2039V27.4312C97.2039 28.1874 97.4178 28.7813 97.8403 29.197C98.2628 29.6126 98.8674 29.8257 99.6435 29.8257C99.9665 29.8261 100.288 29.7784 100.596 29.6843C100.894 29.599 101.168 29.4492 101.399 29.2459L101.429 29.2179L100.994 28.3446L100.946 28.383Z" fill="white"/>
            <path d="M108.301 22.0463C107.667 21.7026 106.956 21.5235 106.234 21.5259C105.511 21.5283 104.802 21.7122 104.171 22.0603C103.565 22.4126 103.065 22.9193 102.725 23.5274C102.365 24.183 102.182 24.9194 102.194 25.6652C102.182 26.4168 102.371 27.1583 102.744 27.8135C103.106 28.4331 103.633 28.9425 104.268 29.2859C104.961 29.6535 105.738 29.8382 106.524 29.822C107.145 29.8266 107.761 29.7078 108.334 29.4727C108.868 29.257 109.341 28.9159 109.711 28.4789L109.74 28.444L109.05 27.6615L109.011 27.7069C108.717 28.0499 108.343 28.3182 107.922 28.4894C107.488 28.6621 107.024 28.7493 106.556 28.7462C105.98 28.7562 105.41 28.6242 104.899 28.3619C104.43 28.1171 104.04 27.7467 103.773 27.293C103.552 26.9115 103.419 26.4861 103.384 26.0477H110.22V25.9953C110.22 25.9342 110.22 25.8783 110.229 25.8207C110.238 25.763 110.229 25.7071 110.229 25.6547C110.244 24.9029 110.066 24.1596 109.711 23.4942C109.385 22.8926 108.897 22.3917 108.301 22.0463ZM103.741 23.9903C103.973 23.5525 104.326 23.189 104.76 22.9423C105.209 22.6881 105.718 22.5578 106.236 22.565C106.753 22.5559 107.263 22.6857 107.712 22.9406C108.138 23.1939 108.487 23.5558 108.723 23.9885C108.919 24.3461 109.04 24.7396 109.077 25.1447H103.397C103.434 24.7414 103.551 24.3492 103.741 23.9903Z" fill="white"/>
            <path d="M118.794 18.2794L118.72 22.9951C118.429 22.5888 118.051 22.2503 117.613 22.0031C117.019 21.6766 116.349 21.5104 115.669 21.521C114.932 21.5117 114.205 21.6923 113.56 22.045C112.944 22.3859 112.436 22.8882 112.091 23.4964C111.74 24.1658 111.558 24.9085 111.558 25.6621C111.558 26.4158 111.74 27.1584 112.091 27.8279C112.434 28.4409 112.942 28.9485 113.558 29.295C114.203 29.6496 114.931 29.8303 115.669 29.819C116.353 29.8297 117.029 29.661 117.626 29.3299C118.081 29.0671 118.469 28.7058 118.763 28.2733V29.7421H119.922L119.996 18.2759L118.794 18.2794ZM115.75 28.7588C115.222 28.7657 114.701 28.6331 114.242 28.3746C113.795 28.1164 113.428 27.7418 113.182 27.2917C112.921 26.7888 112.786 26.2322 112.786 25.6674C112.786 25.1026 112.921 24.5459 113.182 24.0431C113.43 23.5967 113.797 23.2254 114.242 22.9689C114.701 22.7104 115.222 22.5778 115.75 22.5847C116.286 22.5767 116.814 22.7093 117.281 22.9689C117.728 23.2231 118.096 23.595 118.342 24.0431C118.596 24.5476 118.729 25.1036 118.729 25.6674C118.729 26.2311 118.596 26.7872 118.342 27.2917C118.097 27.743 117.73 28.118 117.281 28.3746C116.814 28.6324 116.285 28.7631 115.75 28.7536V28.7588Z" fill="white"/>
          </svg>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import AppLogo from "@/components/AppLogo.vue";

export default {
  name: "AuthFormContainer",
  components: {AppLogo},
  props: {
    title: {
      type: String,
      default: ''
    },
    comment: {
      type: String,
      default: ''
    }
  },
  computed: {
    background() {
      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      const imageNumber = getRandomInt(1, 8)
      return { 'background': `url(${require(`@/assets/auth/${ imageNumber }.png`)})` }
    }
  }
}
</script>


<style scoped lang="scss">
  $commonPadding: 24px;
  $textColor: #1C262F;

  .auth-container {
    min-height: 100vh;
    width: 100%;
    max-height: 100%;
    display: flex;
    background: #F2F5F8;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      height: 80px;
      background: white;
      box-shadow: 0px 1px 4px 0px #00000033;
      padding: 0 $commonPadding;
    }

    &__body {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      padding: $commonPadding;
      overflow: hidden;
      width: 1300px;
      margin: 0 auto;
    }

    &__footer {
      padding: $commonPadding 0;
    }

    &__content {
      flex-basis: 50%;
      display: flex;
      justify-content: center;
      padding-top: 64px;
      padding-bottom: 24px;
      background: white;
      border-radius: 8px 0 0 8px;
      max-width: 1300px;
    }

    &__form-wrapper {
      display: flex;
      flex-direction: column;
      width: 543px;
    }

    &__form-slot {
      flex-grow: 1;

      &_short {
        margin-top: 64px;
      }
    }

    &__comment {
      color: #677A89;
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    &__banner {
      border-radius: 0 8px 8px 0;
      flex-basis: 50%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &-partial {
        height: 81px;
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        & span {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
      }

      &-title {
        flex-grow: 1;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & h3 {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 13px;
          line-height: 32px;
          text-align: center;
          color: rgba(255, 255, 255, 1);
          max-width: 260px;
        }

        & h4 {
          font-weight: 600;
          font-family: Montserrat, sans-serif;
          font-size: 28px;
          line-height: 52px;
          color: rgba(255, 255, 255, 1);
          max-width: 260px;
          text-align: center;
        }
      }

      &:after {
        content: '';
        position: absolute;
        background: linear-gradient(108.53deg, #5EC7EB 0%, #0895D1 52.86%, #00437A 101.49%);
        opacity: 0.9;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      & img {
        max-height: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__title {
      color: $textColor;
      font-size: 24px;
      font-weight: 600;
      font-family: 'Montserrat';
      margin-bottom: 24px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 60px;
        height: 2px;
        background: #0081C2;
      }
    }

  }

</style>
